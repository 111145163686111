<template>
	<div class="usercontainer">
		<div>
			<el-form :inline="true" ref="queryForm" :model="queryForm">
				<el-form-item>
					<el-input v-model="queryForm.userName" placeholder="按账号查询"></el-input>
				</el-form-item>
				<el-form-item>
					<el-input v-model="queryForm.nickName" placeholder="按昵称查询"></el-input>
				</el-form-item>
				<el-form-item>
					<el-button icon="el-icon-search" @click="list" type="danger">查询</el-button>
					<el-button icon="el-icon-plus" @click="add" type="primary">新增</el-button>
				</el-form-item>
			</el-form>
		</div>
		<el-table :data="tableData">
			<el-table-column prop="manager_username" label="账号" min-width="200"></el-table-column>
			<el-table-column prop="manager_nickname" label="昵称" min-width="200"></el-table-column>
			<!-- <el-table-column prop="roleName" label="角色名称"></el-table-column> -->
			<el-table-column prop="create_time" label="创建时间" min-width="200"></el-table-column>
			<el-table-column align="center" label="操作" min-width="250">
				<template slot-scope="scope">
					<el-link @click="edit(scope.row)" :underline="false" type="primary" class="el-icon-edit-outline" v-if="scope.row.id!=nowUserID">编辑</el-link>
					<span style="display: inline-block;width: 20px;" v-if="scope.row.id!=nowUserID"></span>
					<span style="display: inline-block; color: #67c23a;" v-if="scope.row.id==nowUserID">当前登录用户</span>
					<el-link @click="del(scope.row)" :underline="false" type="danger" class="el-icon-delete" v-if="scope.row.id!=nowUserID">删除</el-link>
				</template>
			</el-table-column>
		</el-table>

		<el-pagination 
			background 
			style="margin-top: 20px;"
			layout="total,prev, pager, next,jumper" 
			@current-change="handleCurrentChange"
			:current-page="pageNo" 
			:total="total">
		</el-pagination>

		<el-dialog :visible.sync="addVisible" v-if="addVisible" :close-on-click-modal="false">
			<Add @after="list" @hideDialog="hidden"></Add>
		</el-dialog>

		<el-dialog :visible.sync="editVisible" v-if="editVisible" :close-on-click-modal="false">
			<Edit @after="list" @hideDialog="hidden" :data="formData"></Edit>
		</el-dialog>

	</div>
</template>

<script>
	import {del,query} from "../../../../api/user.js";
	import Add from './UserAdd'
	import Edit from './UserEdit'
	import token from '@/token.js'

	export default {
		name: "Index",
		components: {
			Add,
			Edit
		},
		data() {
			return {
				nowUserID:token.getUser().id,
				queryForm: {
					userName: '',
					nickName: '',
				},
				defForm:{
					id: 0,
					roleId: 0,
					admin: 'normal',
					userName: '',
					nickName: '',
					passWord: '',
					updateUserID: '',
					updateUserName: ''
				},
				tableData: [],
				formData: {},
				total: 0,
				pageNo: 1,
				addVisible: false,
				editVisible: false,
			}
		},
		mounted() {
			this.list();
		},
		methods: {
			//改变当前页
			handleCurrentChange(val) {
				this.pageNo = val;
				this.list()
			},
			//查询用户列表
			list() {
				let param={
					userName: this.queryForm.userName,
					nickName: this.queryForm.nickName,
					page: this.pageNo,
					pageSize: 10,
				}
				query(param).then(data => {
					this.tableData = data.data.list
					this.total = data.data.total
				}).catch(error => {
					this.$message.error(error)
				})
			},
			//新增用户信息
			add() {
				this.addVisible = true
			},
			edit(row) {
				this.editVisible = true
				this.formData = row
			},
			del(row) {
				this.defForm.id=row.id,
				this.defForm.roleId=row.role_id,
				this.defForm.admin=row.manager_admin,
				this.defForm.userName=row.manager_username,
				this.defForm.nickName=row.manager_nickname,
				this.defForm.passWord=row.manager_password,
				this.defForm.updateUserID=token.getUser().id,
				this.defForm.updateUserName=token.getUser().manager_username,
				
				this.$confirm('确定要删除吗？', "提示",{
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					del(this.defForm).then(data => {
						let totalPage = Math.ceil((this.total - 1) / 10); //15 是每页15条
						let pageNo = this.pageNo > totalPage ? totalPage : this.pageNo;
						this.pageNo = pageNo < 1 ? 1 : pageNo; //this.pageNo 当前页数
						this.list()
						this.$message.success("管理员["+row.manager_username+"]删除成功!")
					}).catch(error => {
						this.$message.error(error)
					})
				}).catch(() => {
					
				})
			},
			hidden() {
				this.addVisible = false
				this.editVisible = false
			},
		}
	}
</script>

<style scoped>
	.usercontainer {}
</style>
