<template>
	<div>
		<el-form ref="defForm" :rules="rules" :model="defForm" label-width="80px">
			<el-form-item label="账号" prop="userName">
				<el-input v-model="defForm.userName"></el-input>
			</el-form-item>
			<el-form-item label="密码" prop="passWord">
				<el-input type="password" v-model="defForm.passWord"></el-input>
			</el-form-item>
			<el-form-item label="昵称" prop="nickName">
				<el-input v-model="defForm.nickName"></el-input>
			</el-form-item>

			<el-form-item size="large">
				<el-button type="primary" @click="close">取消</el-button>
				<el-button type="primary" :loading="loading" @click="submitForm">保存</el-button>
			</el-form-item>
		</el-form>
	</div>
</template>
<script>
	import {update} from '@/api/user'
	import token from '@/token.js'
	export default {
		name: "Edit",
		props: ['data'],
		data() {
			return {
				defForm: {
					id: 0,
					roleId: 0,
					admin: 'normal',
					userName: '',
					nickName: '',
					passWord: '',
					updateUserID: token.getUser().id,
					updateUserName: token.getUser().manager_username
				},
				loading: false,
				rules: {
					userName: [{
						required: true,
						message: '必填项不能为空'
					}],
					passWord: [{
						required: true,
						message: '必填项不能为空'
					}],
				}
			}
		},
		created() {
			let editItem=JSON.parse(JSON.stringify(this.data))
			// console.log("编辑",editItem)
			this.defForm.id = editItem.id
			this.defForm.userName = editItem.manager_username
			this.defForm.nickName = editItem.manager_nickname
			this.defForm.passWord = editItem.manager_password
		},
		methods: {
			submitForm() {
				this.$refs['defForm'].validate(valid => {
					if (valid) {
						this.loading = true
						update(this.defForm).then(data => {
							this.loading = false
							this.$message.success("管理员["+this.defForm.userName+"]编辑成功!")
							this.$refs['defForm'].resetFields()
							this.$emit("after")
							this.$emit("hideDialog")
						}).catch(error => {
							this.loading = false
							this.$message.error(error)
						})
					}
				})
			},
			close() {
				this.$emit("hideDialog")
			}
		}
	}
</script>

<style scoped>

</style>
